<template>
  <v-container>
    <v-card elevation="12" min-height="600">
      <v-snackbar
        centered
        v-model="permSnackbar"
        :color="snackbarColor"
        :timeout="timeout"
      >
        {{ errorText }}
        <template v-slot:action="{ attrs }">
          <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-card-title class="justify-center"
        ><v-row>
          <v-col cols="2"></v-col><v-col cols="8">I MIEI DATI</v-col></v-row
        ></v-card-title
      >
      <v-card-text>
        <v-row v-show="loading" justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular
              color="primary"
              indeterminate
              :size="70"
              :width="7"
            />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
        <v-row>
          <v-row align="end">
            <v-spacer></v-spacer>
            <v-col cols="3" class="text-right" align-self="center"
              >Matricola:</v-col
            >
            <v-col cols="9">
              <span>{{ userdata.MATRICOLA }}</span>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-row align="end">
            <v-spacer></v-spacer>
            <v-col cols="3" class="text-right" align-self="center">Nome:</v-col>
            <v-col cols="9">
              <span>{{ userdata.NOME }}</span>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-row align="end">
            <v-spacer></v-spacer>
            <v-col cols="3" class="text-right" align-self="center"
              >Cognome:</v-col
            >
            <v-col cols="9">
              <span>{{ userdata.COGNOME }}</span>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-row align="end">
            <v-spacer></v-spacer>
            <v-col cols="3" class="text-right" align-self="center"
              >Tipo organico:</v-col
            >
            <v-col cols="9">
              <span>{{ userdata.DESCRIZIONE_TIPO_ORGANICO }}</span>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-row align="end">
            <v-spacer></v-spacer>
            <v-col cols="3" class="text-right" align-self="center"
              >Ruolo:</v-col
            >
            <v-col cols="9">
              <span>{{ userdata.DS_RUOLO }}</span>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-row align="end">
            <v-col cols="3" class="text-right" align-self="center">
            <v-dialog v-model="dialog" width="770" scrollable>
              <template v-slot:activator="{ on, attrs }">
              
                <v-btn small v-bind="attrs" v-on="on"><v-icon color="grey" class="mr-1">mdi-book-open-variant</v-icon>Privacy</v-btn>

              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  INFORMAZIONI AL PERSONALE AFFERENTE AL DIPARTIMENTO DI
                  INFORMATICA DI ATENEO per il trattamento dei dati personali
                  (ai sensi del Regolamento UE 2016/679)
                </v-card-title>

                <v-card-text>
                  <strong>Premessa</strong><br />
                  Ai sensi dell’art. 13 del Regolamento UE 2016/679 (“RGPD”)
                  relativo alla protezione delle persone fisiche con riguardo al
                  trattamento dei dati personali, nonché alla libera
                  circolazione di tali dati e che abroga la direttiva 95/46/CE,
                  l’Università degli Studi di Milano informa il personale
                  afferente al Dipartimento di Informatica dell’Ateneo in merito
                  all’utilizzo dei dati personali che li riguardano. Resta ferma
                  l’osservanza da parte dell’Università degli Studi di Milano
                  della vigente normativa in materia di trasparenza e di
                  pubblicazione obbligatoria di dati e documenti.<br />
                  <strong
                    >1. Titolare del trattamento e Responsabile per la
                    protezione dei dati (RPD)</strong
                  ><br />
                  Titolare del trattamento e Responsabile per la protezione dei
                  dati (RPD) Titolare del trattamento dei dati è l’Università
                  degli Studi di Milano, nella persona del Rettore pro tempore,
                  Via Festa del Perdono n. 7, 20122 Milano, e-mail
                  infoprivacy@unimi.it. Ai sensi degli artt. 37 e seguenti del
                  Regolamento UE 2016/679, l’Ateneo ha nominato il Responsabile
                  per la protezione dei dati (RPD) individuato nell’Avv.
                  Pierluigi Perri, c/o Dipartimento “Cesare Beccaria”, Via Festa
                  del Perdono n. 3, 20122 Milano, e-mail dpo@unimi.it.<br />
                  <strong>2. Finalità e base giuridica del trattamento</strong
                  ><br />
                  I dati personali trattati sono dati anagrafici, dati relativi
                  alla residenza, numero telefonico dell’abitazione e/o numero
                  di cellulare. Tali dati vengono trattati, nell’ambito delle
                  finalità istituzionali dell’Ateneo, per consentire i contatti
                  tra il Dipartimento e il personale e garantire il regolare
                  espletamento delle attività del Dipartimento. La base
                  giuridica del trattamento è l’adempimento di un compito di
                  interesse pubblico. Il conferimento dei dati è requisito
                  necessario per il regolare svolgimento dei compiti
                  istituzionali dell’Università degli Studi di Milano.
                  <br />
                  <strong>3. Modalità del trattamento</strong><br />
                  La raccolta dei dati avviene nel rispetto dei principi di
                  pertinenza, completezza e non eccedenza in relazione ai fini
                  per i quali sono trattati. I dati personali conferiti sono
                  trattati in osservanza dei principi di liceità, correttezza e
                  trasparenza, previsti dall’articolo 5 del RGPD, anche con
                  l’ausilio di strumenti informatici e telematici atti a
                  memorizzare e gestire i dati stessi, e, comunque, in modo tale
                  da garantirne la sicurezza e tutelare la massima riservatezza
                  dell’interessato. In particolare, sono adottate misure di
                  sicurezza informatica conformi alle previsioni dell’art. 32
                  del GDPR ed alla Circolare AgID n. 2/2017 “Misure minime di
                  sicurezza ICT per le pubbliche amministrazioni” volte a
                  prevenire la perdita dei dati, usi illeciti o non corretti ed
                  accessi non autorizzati.<br />
                  <strong
                    >4. Categorie di soggetti autorizzati al trattamento e ai
                    quali i dati possono essere comunicati</strong
                  ><br />
                  I dati personali saranno trattati, nel rispetto della vigente
                  normativa in materia, dai dipendenti dell’Università degli
                  Studi di Milano (in qualità di Autorizzati al trattamento) in
                  servizio presso il Dipartimento di Informatica o presso altre
                  strutture dell’Ateneo che ne facciano richiesta, per le
                  finalità istituzionali dell’Ateneo o in osservanza di obblighi
                  legislativi".<br />
                  È fatta salva, in ogni caso, la comunicazione o diffusione di
                  dati richiesti, in conformità alla legge, dall’autorità di
                  Pubblica Sicurezza, dall’Autorità Giudiziaria o da altri
                  soggetti pubblici per finalità di difesa, sicurezza dello
                  Stato ed accertamento dei reati, nonché la comunicazione
                  all’Autorità Giudiziaria in ottemperanza ad obblighi di legge,
                  laddove si ravvisino ipotesi di reato. Al di fuori dei
                  predetti casi, i dati personali non vengono in nessun modo e
                  per alcun motivo comunicati o diffusi a terzi. I dati
                  personali non sono oggetto di trasferimento verso Paesi terzi
                  od organizzazioni internazionali.<br />

                  <strong>5. Conservazione</strong><br />
                  I dati personali saranno conservati in apposito data base su
                  server del Dipartimento per la durata di afferenza alla
                  struttura di ciascun dipendente.<br />

                  <strong>6. Diritti dell'interessato</strong><br />
                  L’Interessato può proporre reclamo a un’Autorità di controllo
                  ed esercitare ai sensi degli artt. da 15 a 22 del RGPD il
                  diritto di:
                  <ul>
                    <li>
                      chiedere al titolare del trattamento l'accesso ai dati
                      personali che lo riguardano, la rettifica o la
                      cancellazione degli stessi, la limitazione del
                      trattamento;
                    </li>
                    <li>opporsi al trattamento;</li>
                    <li>chiedere la portabilità dei dati,</li>
                  </ul>
                  rivolgendosi ai seguenti contatti: Responsabile della
                  Protezione dei Dati, via Festa del Perdono, 7, 20122 Milano -
                  e-mail: dpo@unimi.it .
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            </v-col>
            <v-col cols="9">
              <v-switch
                v-model="privacyAssenso"
                @change="change"
                height="12"
                :hint="
                  userdata.PRIVACY_ASSENSO && !privacyAssenso
                    ? 'Non è stato fornito il consenso al trattamento dei dati. ATTENZIONE: la rimozione del consenso comporta la rimozione irreversibile dei dati'
                    : !privacyAssenso
                    ? 'Non è stato fornito il consenso al trattamento dei dati'
                    : 'È stato fornito il consenso al trattamento dei dati'
                "
                persistent-hint
              ></v-switch>
            </v-col>
          </v-row>
        </v-row>
        <v-row v-if="privacyAssenso"
          ><v-col cols="12">
            <v-row>
              <v-col cols="3" class="text-right" align-self="center"
                >Indirizzo abitazione:</v-col
              >
              <v-col cols="9">
                <v-text-field
                  v-model="privacydata.PRIVACY_INDIRIZZO"
                  @keyup="change"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-right" align-self="center"
                >Telefono abitazione:</v-col
              >
              <v-col cols="9">
                <v-text-field
                  v-model="privacydata.PRIVACY_TELEFONOCASA"
                  @keyup="change"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-right" align-self="center"
                >Telefono mobile:</v-col
              >
              <v-col cols="9">
                <v-text-field
                  v-model="privacydata.PRIVACY_TELEFONOMOBILE"
                  @keyup="change"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="9">
            <v-btn @click="saveUserData" :disabled="!save">Salva</v-btn>

            </v-col>
        </v-row>
        <v-row> </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

//const axios = require('axios');

export default {
  name: "MyRooms",
  data: () => {
    return {
      loading: false,
      userdata: {},
      privacydata: {},
      privacyAssenso: false,
      permSnackbar: false,
      snackbarColor: "success",
      errorText: "",
      timeout: -1,
      save: false,
      dialog: false,
    };
  },

  created: function () {
    this.loading = true;
    this.$http
      .get(
        process.env.VUE_APP_PATRIMONIO_API +
          "/users/" +
          this.$store.state.userMatricola +
          "/data",
        {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        this.userdata = response.data;
        this.privacyAssenso = this.userdata.PRIVACY_ASSENSO;
        this.privacydata.PRIVACY_INDIRIZZO = this.userdata.PRIVACY_INDIRIZZO;
        this.privacydata.PRIVACY_TELEFONOCASA =
          this.userdata.PRIVACY_TELEFONOCASA;
        this.privacydata.PRIVACY_TELEFONOMOBILE =
          this.userdata.PRIVACY_TELEFONOMOBILE;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    saveUserData() {
      this.loading = true;
      this.privacydata.PRIVACY_ASSENSO = this.privacyAssenso;
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$store.state.userMatricola +
            "/data",
          this.privacydata,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.errorText = "Dati salvati correttamente";
          this.snackbarColor = "success";
          this.permSnackbar = true;
          this.timeout = 2000;
          this.userdata.PRIVACY_ASSENSO = this.privacyAssenso;
          if (!this.privacyAssenso) {
            this.privacydata.PRIVACY_INDIRIZZO = null;
            this.privacydata.PRIVACY_TELEFONOCASA = null;
            this.privacydata.PRIVACY_TELEFONOMOBILE = null;
          }

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorText = "Errore nel salvataggio dei dati";
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;

          this.loading = false;
        });
    },
    change() {
      // se è diverso (modificato) almeno uno campi del form rispetto al valore dal database,
      // le variabili sono TRUE e puoi salvare
      let assenso = this.privacyAssenso != this.userdata.PRIVACY_ASSENSO;
      let indirizzo =
        this.privacydata.PRIVACY_INDIRIZZO != this.userdata.PRIVACY_INDIRIZZO;
      let telefonocasa =
        this.privacydata.PRIVACY_TELEFONOCASA !=
        this.userdata.PRIVACY_TELEFONOCASA;
      let telefonomobile =
        this.privacydata.PRIVACY_TELEFONOMOBILE !=
        this.userdata.PRIVACY_TELEFONOMOBILE;

      if (assenso || indirizzo || telefonocasa || telefonomobile) {
        this.save = true;
      } else {
        this.save = false;
      }

      // se c'è l'assenso ma i campi sono vuoti, non puoi salvare
      if (
        this.privacyAssenso &&
        this.privacydata.PRIVACY_INDIRIZZO === "" &&
        this.privacydata.PRIVACY_TELEFONOCASA === "" &&
        this.privacydata.PRIVACY_TELEFONOMOBILE === ""
      ) {
        this.save = false;
      }
    },
  },
};
</script>
